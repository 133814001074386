<template>
  <button
    class="button format-button"
    :class="{ 'button--active': active }"
    @click="$emit('input', content)"
  >
    {{ title }}
  </button>
</template>

<script>
export default {
  props: ['val', 'active', 'title'],
  data() {
    return {
      content: this.val,
    };
  },
};
</script>

<style scoped>
.button {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #fff;
  color: #192229;
  border: 1px solid #d5dfe4;
  border-radius: 0;
  position: relative;
  width: calc(50% - 10px);
  margin-bottom: 10px;
  text-align: left;
  padding-left: 32px;
  line-height: 12px;
}

.button::before {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  top: 8px;
  left: 9px;
  border: 1px solid #d5dfe4;
  border-radius: 50%;
}

.button::after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  top: 11px;
  background-color: #fff;
  left: 12px;
  border-radius: 50%;
}

.button--active {
  background-color: #3897f0;
  border-color: #3897f0;
  color: #fff;
}
</style>
